import { Fragment } from "react";
import SectionContainer from "../sectionContainer/SectionContainer";
import VideoPreview from "../video/video";
import PDFPreview from "../pdf/pdf";
import TutQuest from "../tutorialQuestion/TutQuest";
import MiniExam from "../exam/exam";

import video from "../../../../../assets/preview/SeaV.mp4";
import file from "../../../../../assets/preview/test.pdf";

const Tutorial = () => {
  const getComponant = (tutorial) => {
    if (tutorial.type === 0) {
      return <VideoPreview url={tutorial.data.url} />;
    } else if (tutorial.type === 1) {
      return <PDFPreview url={tutorial.data.url} />;
    } else {
      return <TutQuest questions={tutorial.data.question} />;
    }
  };

  return (
    <Fragment>
      <SectionContainer
        label={"فيــديو الشرح"}
        componant={<VideoPreview url={video} />}
        show={true}
      />

      <SectionContainer
        label={"ملخص الشرح"}
        componant={<PDFPreview url={file} />}
        show={true}
      />

      {/* <SectionContainer
        label={"ملخص الشرح"}
        componant={<MiniExam exam={tutorials} />}
        hideGoNext={true}
        show={true}
      /> */}
    </Fragment>
  );
};

export default Tutorial;
