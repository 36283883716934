import axios from "axios";
import { redirect } from "react-router-dom";

const RegisterUser = async ({ request }) => {
  let data = await request.formData();

  let body = {
    full_name: data.get("name"),
    phone: data.get("phone"),
    parent_phone: data.get("parentPhone"),
    email: data.get("email"),
    gender: data.get("gender"),
    password: data.get("password"),
    password_confirmation: data.get("confPassword"),
  };

  let retValues = {
    response: {},
    err: [],
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}register`, body)
    .then((res) => {
      retValues.response = { ...res.data };
    })
    .catch((err) => {
      retValues.err.push(err.response.data.message);
    });

  if (retValues.err.length == 0) {
    return redirect("/status");
  }

  return retValues;
};

export default RegisterUser;
