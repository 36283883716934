import axios from "axios";

const getAllLectures = async (id) => {
  let rtValues = {
    data: [],
    err: null,
  };
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}lectures/${id}`)
    .then((res) => {
      rtValues.data = res.data.data;
    })
    .catch((err) => {
      rtValues.err = err.response;
    });

  return rtValues;
};

export const getLectureContent = async (id) => {
  let retValues = {
    response: null,
    err: [],
  };

  await axios
    .get(`${process.env.REACT_APP_BASE_URL}show_lecture/${id}`)
    .then((res) => {
      retValues.response = { ...res.data.data };
    })
    .catch((err) => {
      retValues.err.push(err.response.data.message);
    });

  return retValues;
};

export const requestCourse = async (id) => {
  console.log(id);
  let retValues = {
    response: null,
    err: [],
  };

  let body = {
    session_id: id,
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}send_request`, body)
    .then((res) => {
      retValues.response = { ...res.data.data };
    })
    .catch((err) => {
      console.log(err);
      retValues.err.push(err.response.data.message);
    });

  return retValues;
};

export default getAllLectures;
