import axios from "axios";

const logoutUser = async () => {
  let retValues = {
    response: null,
    err: [],
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}logout`)
    .then((res) => {
      retValues.response = { ...res.data.data };
    })
    .catch((err) => {
      retValues.err.push(err.response.data.message);
    });

  return retValues;
};

export default logoutUser;
