import { Fragment } from "react";
import style from "./pending.module.css";
import { Link } from "react-router-dom";

const Pending = () => {
  return (
    <Fragment>
      <section className={`container ${style.pending}`}>
        <h3>لم تتم الموافقة علي طلبك بعد</h3>
        <p>في حال التأخر في الرد برجاء التواصل معنا</p>
        <Link to={"/contact-us"}>من هنا</Link>
      </section>
    </Fragment>
  );
};

export default Pending;
