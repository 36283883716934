import { useState } from "react";
import style from "../../ViewCourse.module.css";

const SectionContainer = ({ label, componant, show, hideGoNext }) => {
  const showElement = () => {
    return show ? style.show : "";
  };

  return (
    <div className={style.sectionContianer + " " + showElement()}>
      <button>{label}</button>
      <div>
        {componant}
        <div>{!hideGoNext && <button>الذهاب للمرحلة التالــية</button>}</div>
      </div>
    </div>
  );
};

export default SectionContainer;
