import { Fragment, useEffect, useState } from "react";
import "./ChapterLectures.css";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faKey } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../Tools/loading/Loading";
import getAllLectures from "../../../services/Lectures/lecture";
import NoDataFound from "../../Tools/noDataFound/noData";

const ChapterLectures = () => {
  let { id } = useParams();
  let [response, setResponse] = useState({
    data: [],
    err: null,
  });
  let [pending, setPending] = useState(true);

  const status = (status) => {
    if (status === "pending") {
      return { icon: faKey, class: "waiting" };
    } else if (status === "opened") {
      return { icon: faLockOpen, class: "opened" };
    } else {
      return { icon: faLock, class: "closed" };
    }
  };

  const urlRecognize = (status) => {
    if (status === "open") {
      return "./lecture/start-course/";
    } else {
      return "./lecture/view-content/";
    }
  };

  const getData = async () => {
    let values = await getAllLectures(id);
    setResponse(values);
    setPending(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const viewCondition = () => {
    if (response.err === null) {
      if (response.data.length !== 0)
        return (
          <section className="container AllLecturesContainer">
            {response.data.map((lecture, idx) => (
              <Link
                to={urlRecognize(lecture.status) + lecture.id}
                key={idx}
                className="lecture">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url("${lecture.image}")`,
                  }}>
                  <div className={"status " + status(lecture.status).class}>
                    <FontAwesomeIcon icon={status(lecture.status).icon} />
                  </div>
                </div>
                <div className="name">
                  <p>{lecture.title}</p>
                </div>
              </Link>
            ))}
          </section>
        );
      else return <NoDataFound type={1} />;
    } else return <NoDataFound />;
  };

  return (
    <Fragment>
      {pending && <Loading />}
      {viewCondition()}
    </Fragment>
  );
};

export default ChapterLectures;
