import React, { Fragment, useContext, useEffect, useState } from "react";

// Services
import { Form, useActionData, useNavigate } from "react-router-dom";

// Partials
import Password from "../../Tools/password/Password";
import Input from "../../Tools/input/Input";
import { Alert } from "@mui/material";
import Waiting from "../../Tools/Waiting/Waiting";
import { AuthContext } from "../../../services/Authentication/AuthContext";

const Login = () => {
  let response = useActionData();
  let { login } = useContext(AuthContext);
  let nav = useNavigate();
  let [pending, setPending] = useState(false);

  useEffect(() => {
    if (response) {
      setPending(false);
      if (response.response) {
        login(response.response);
        nav(-1);
      }
    }
  }, [response]);

  return (
    <Fragment>
      {pending && <Waiting msg={"برجاء الأنتظار جاري تسجيل الدخول"} />}
      <section className="formContainer">
        <div className="container">
          <h2>تسجيل الدخول</h2>
          <Form method="post" action="/login">
            <div
              style={{
                marginBottom: "20px",
              }}>
              {response && response.err.length !== 0 && (
                <Alert variant="filled" severity="error">
                  <span className="errMSG">{response.err[0]}</span>
                </Alert>
              )}
            </div>
            <div className="formControl">
              <Input
                label={"رقم الهاتف"}
                type={"phone"}
                name={"phone"}
                validate={-1}
              />
            </div>

            <div className="formControl" id="Password">
              <Password label={"كلمة المرور"} name={"password"} validate={-1} />
            </div>

            <div className="formSubmit">
              <button
                onClick={() => {
                  setPending(true);
                }}>
                تسجيل الدخول
              </button>
            </div>
          </Form>
        </div>
      </section>
    </Fragment>
  );
};

export default Login;
