import { Fragment, useContext, useState } from "react";
import Navbar from "../../Tools/navbar/Navbar";
import Footer from "../../Tools/footer/Footer";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  return (
    <Fragment>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
