import style from "../../ViewCourse.module.css";

const VideoPreview = ({ url }) => {
  return (
    <div className={style.viewContainer}>
      <video src={url} controls></video>
      <div>
        <p></p>
      </div>
    </div>
  );
};

export default VideoPreview;
