import React, { Fragment, useContext, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import logo from "../../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../services/Authentication/AuthContext";
import logoutUser from "../../../services/Authentication/logout";
import Waiting from "../../Tools/Waiting/Waiting";

const Navbar = () => {
  let sideNav = useRef(null);
  let [pending, setPending] = useState(false);
  let { user, logout } = useContext(AuthContext);

  const toggleSideNav = () => {
    sideNav.current.classList.toggle("show");
  };

  const handleLogout = async () => {
    setPending(true);
    await logoutUser();
    logout();
    setPending(false);
  };

  return (
    <Fragment>
      {pending && <Waiting msg={"برجاء الإنتظار ... جاري تسجيل الخروج"} />}
      <nav>
        <div className="container">
          <div className="logo">
            <h1>
              <NavLink to="/">
                <img src={logo} alt="logo" />
              </NavLink>
            </h1>
          </div>

          <div className="side" ref={sideNav}>
            <div className="overlay" onClick={toggleSideNav}></div>
            <div className="urls">
              <div className="links">
                <ul>
                  <li>
                    <NavLink to="/">الرئيسية</NavLink>
                  </li>
                  <li>
                    <NavLink to="/chapters">المحاضرات</NavLink>
                  </li>
                  <li>
                    <NavLink to="/faq">الأسئلة الشائعة</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us">اتصل بنا</NavLink>
                  </li>
                </ul>
              </div>

              {user ? (
                <div className="user">
                  <p>محمد عاطف عويس احمد</p>

                  <button onClick={handleLogout}>Logout</button>
                </div>
              ) : (
                <div className="log">
                  <NavLink to="/login">تسجيل الدخول</NavLink>
                  <NavLink to="/register">الإشتراك</NavLink>
                </div>
              )}
            </div>
          </div>

          <div className="sideBTN" onClick={toggleSideNav}>
            <button>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
