import { Fragment } from "react";

const FAQ = () => {
  return (
    <Fragment>
      <section className="FAQs container">
        <section className="howToUse">
          <h4>قسم المساعدة</h4>
          <div className="steps">
            <div className="step">
              <h5>سؤال الاول: كيف تتم عملية الدفع</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
            <div className="step">
              <h5>سؤال الاول: كيف تتم عملية الدفع</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
            <div className="step">
              <h5>سؤال الاول: كيف تتم عملية الدفع</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
          </div>
        </section>
      </section>
    </Fragment>
  );
};

export default FAQ;
