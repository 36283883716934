import { Fragment } from "react";
import style from "./Loading.module.css";

const Loading = ({ pending }) => {
  return (
    <Fragment>
      <div className={style.Loading}>
        <span className={style.loader}></span>
      </div>
    </Fragment>
  );
};

export default Loading;
