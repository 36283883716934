export const emailValidate = (email, setErrors) => {
  let errs = [];

  const validate = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+(?:.[a-zA-Z0-9-]+)+$"
  );

  if (email.length === 0) {
    errs.push("هذا الحقل مطلوب");
  } else if (!validate.test(email)) {
    errs.push("تأكد من إدخال حساب إلكتروني صحيح");
    errs.push("EX. exampel@example.com");
  }

  setErrors(errs);
};

export const passwordValidate = (password, setErrors, confPassword = "") => {
  let errs = [];
  if (password.length < 8) {
    errs.push("كلمة المرور يجب ان تكون اكثر من 8 أحرف");
  }

  if (!/[A-Z]+/.test(password))
    errs.push("يجب أن تحتوي كلمة المرور علي أحرف كبيرة");

  if (!/[a-z]+/.test(password))
    errs.push("يجب أن تحتوي كلمة المرور علي أحرف صغيرة");

  setErrors(errs);
};

export const confirmPasswordValidate = (password, setErrors, confPassword) => {
  let errs = [];
  if (password !== confPassword) {
    errs.push("يجب أن تكون كلمتي المرور متشابهتين");
  }

  setErrors(errs);
};

export const phoneValidate = (phone, setErrors) => {
  let errs = [];

  if (!/01(0|1|2|5)[0-9]{8}/g.test(phone) || phone.length !== 11) {
    errs.push(
      "يجب ان يكون رقم الهاتف مكون من 11 رقماً يبدأ بـ(010 / 011 / 012 / 015)"
    );
  }

  setErrors(errs);
};
