import React, { Fragment, Component, useRef, useState } from "react";
import Password from "../../Tools/password/Password";
import Input from "../../Tools/input/Input";

// Services
import { emailValidate } from "../../Tools/validation/formValidation";
import { Form } from "react-router-dom";

const ContactUs = () => {
  let [errors, setErrors] = useState(true);

  let Submition = (e) => {
    if (errors) {
      e.preventDefault();
    }
  };

  return (
    <Fragment>
      <section className="formContainer">
        <div className="container">
          <h2>تواصل معنا</h2>
          <Form method="post" action="/contact-us">
            <div className="formControl">
              <Input label={"الإسم كامل"} name={"name"} />
            </div>

            <div className="formControl">
              <Input
                label={"البريد الإلكتروني"}
                type={"email"}
                name={"email"}
                errors={errors}
                setErrors={setErrors}
                validator={emailValidate}
              />
            </div>

            <div className="formControl">
              <label htmlFor="message">ما الذي تريد الاستفسار عنه</label>
              <textarea
                name="message"
                placeholder="الرسالة"
                id="message"
                required></textarea>
            </div>

            <div className="formSubmit">
              <button onClick={Submition}>تسجيل</button>
            </div>
          </Form>
        </div>
      </section>
    </Fragment>
  );
};

export default ContactUs;
