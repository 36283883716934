import React, { Fragment, useState } from "react";
import "./Register.css";
import Password from "../../Tools/password/Password";
import Input from "../../Tools/input/Input";
import { Alert } from "@mui/material";

// Services
import {
  emailValidate,
  passwordValidate,
  confirmPasswordValidate,
  phoneValidate,
} from "../../Tools/validation/formValidation";
import { Form, useActionData } from "react-router-dom";
import ErrorViewer from "../../Tools/error/ErrorViewer";

const Register = () => {
  // Validation Flags
  let [emailSuccess, setEmailSuccess] = useState(false);
  let [phoneSuccess, setPhoneSuccess] = useState(false);
  let [pPhoneSuccess, setPPhoneSuccess] = useState(false);
  let [passSuccess, setPassSuccess] = useState(false);
  let [confPassSuccess, setconfPassSuccess] = useState(false);

  let ValidationContainer = [
    emailSuccess,
    phoneSuccess,
    pPhoneSuccess,
    passSuccess,
    confPassSuccess,
  ];

  let [pass, setPass] = useState("");

  let formRes = useActionData();

  const handleSubmit = (e) => {
    let validation = true;
    ValidationContainer.forEach((v) => {
      validation = validation && v;
    });

    if (!validation) e.preventDefault();
  };

  return (
    <Fragment>
      <section className="formContainer">
        <div className="container">
          <h2>تسجيل حساب جديد</h2>
          <Form method="post" action="/register">
            <div
              style={{
                marginBottom: "20px",
                position: "sticky",
                top: "15vh",
              }}>
              {formRes && (
                <Alert variant="filled" severity="error">
                  <span className="errMSG">{formRes.err[0]}</span>
                </Alert>
              )}
            </div>
            <div className="formControl">
              <Input label={"الإسم كامل"} name={"name"} />
            </div>

            <div className="formControl">
              <Input
                label={"رقم التليفون"}
                type={"tel"}
                name={"phone"}
                setSuccess={setPhoneSuccess}
                validator={phoneValidate}
              />
            </div>

            <div className="formControl">
              <Input
                label={"رقم ولي الأمر"}
                type={"tel"}
                name={"parentPhone"}
                setSuccess={setPPhoneSuccess}
                validator={phoneValidate}
              />
            </div>

            <div className="formControl">
              <Input
                label={"البريد الإلكتروني"}
                type={"email"}
                name={"email"}
                setSuccess={setEmailSuccess}
                validator={emailValidate}
              />
            </div>

            <div className="formControl">
              <label htmlFor="gender">النوع</label>
              <select placeholder="النوع" id="gender" name="gender">
                <option value="1">ذكر</option>
                <option value="2">انثي</option>
              </select>
            </div>

            <div className="formControl" id="Password">
              <Password
                label={"كلمة المرور"}
                name={"password"}
                validator={passwordValidate}
                setSuccess={setPassSuccess}
                getValue={setPass}
              />
            </div>

            <div className="formControl" id="ConfirmPassword">
              <Password
                label={"تأكيد كلمة المرور"}
                name={"confPassword"}
                coonfirmPassword={pass}
                validator={confirmPasswordValidate}
                setSuccess={setconfPassSuccess}
              />
            </div>

            <div className="formSubmit">
              <button onClick={handleSubmit}>تسجيل</button>
            </div>
          </Form>
        </div>
      </section>
    </Fragment>
  );
};

export default Register;
