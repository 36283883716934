import img from "../../../assets/404/404.svg";
import style from "./404Style.module.css";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <section className={"container spliter " + style.PNF}>
      <div className={style.redirect}>
        <h2>أوبس !! علي ما يبدو انك قد سلكت طريقاً خاطئ</h2>
        <div className={style.details}>
          <p>يمكنك الذهاب إلي: </p>
          <ul>
            <li>
              <Link to="/">الرئيسية</Link>
            </li>
            <li>
              <Link to="/chapters">المحاضرات</Link>
            </li>
            <li>
              <Link to="/faq">الأسئلة الشائعة</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={style.img}>
        <img src={img} alt="404 Not Found" />
      </div>
    </section>
  );
};

export default PageNotFound;
