import axios from "axios";

const getAllChapters = async () => {
  let rtValues = {
    data: [],
    err: null,
  };
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}chapters`)
    .then((res) => {
      rtValues.data = res.data.data;
    })
    .catch((err) => {
      rtValues.err = err.response;
    });

  return rtValues;
};

export default getAllChapters;
