import style from "../../ViewCourse.module.css";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const PDFPreview = ({ url }) => {
  let [growth, setGrowth] = useState(false);
  console.log(growth);
  return (
    <div>
      <div className={`${style.pdfViewer} ${growth ? style.growth : ""}`}>
        <div
          className={style.overlay}
          onClick={() => {
            setGrowth(false);
          }}></div>
        <button
          className={style.growthBTN}
          onClick={() => {
            setGrowth(!growth);
          }}>
          <FontAwesomeIcon icon={faExpand} />
        </button>
        <div className={style.pdfContainer}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={url} theme={"dark"} />;
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default PDFPreview;
