import React, { Fragment, useEffect, useState } from "react";
import "./Password.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Password = ({
  passFunc,
  label,
  validator,
  setSuccess,
  name,
  getValue,
  coonfirmPassword,
  validate,
}) => {
  let [visible, setVisible] = useState(false);
  let [errs, setErrs] = useState([]);
  coonfirmPassword = coonfirmPassword ? coonfirmPassword : "";
  validate = validate === -1 ? false : true;
  validator = validator && validate ? validator : () => {};
  setSuccess = setSuccess ? setSuccess : () => {};
  getValue = getValue ? getValue : () => {};

  useEffect(() => {
    setSuccess(errs.length === 0);
  });

  return (
    <Fragment>
      <div className="formControl">
        <label htmlFor={name}>{label}</label>
        <div className="inp">
          <input
            type={visible ? "text" : "password"}
            id={name}
            name={name}
            onChange={(e) => {
              getValue(e.target.value);
              validator(e.target.value, setErrs, coonfirmPassword);
            }}
            placeholder={label}
            required
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              setVisible(!visible);
            }}>
            <span>
              <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />
            </span>
          </button>
        </div>
        {errs.length > 0 && validate && (
          <div className="inpErr">
            <ul>
              {errs.map((err, idx) => (
                <li key={idx}>
                  <span>{err}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Password;
