const ContactUsService = async ({ request }) => {
  let data = await request.formData();

  let body = {
    name: data.get("name"),
    email: data.get("email"),
    message: data.get("message"),
  };

  console.log(body);

  return null;
};

export default ContactUsService;
