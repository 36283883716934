import { Fragment } from "react";
import style from "./Waiting.module.css";

const Waiting = ({ msg }) => {
  return (
    <Fragment>
      <div className={style.Loading}>
        <span className={style.loader}></span>
        <p>{msg}</p>
      </div>
    </Fragment>
  );
};

export default Waiting;
