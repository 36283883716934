import React, { Fragment, useEffect, useState } from "react";
import logo from "../../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faPhone,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faYoutube } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import getAllChapters from "../../../services/chapters/chapter";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  let [chapters, setChapters] = useState([]);

  const getData = async () => {
    let values = await getAllChapters();
    setChapters(values.data.slice(0, 4));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <footer>
        <div className="container">
          <div className="main">
            <img src={logo} alt="logo" />
            <a href="/">
              <div className="icon">
                <FontAwesomeIcon icon={faLocation} />
              </div>
              الموقع
            </a>
            <a href="tel:0101589145">
              +20 10 158 9145
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
            </a>
            <a href="tel:0101589145">
              <div className="icon">
                <FontAwesomeIcon icon={faMessage} />
              </div>
              info@gmail.com
            </a>
          </div>
          <div className="links">
            <h4>روابط هامة</h4>
            <ul>
              <li>
                <NavLink to="/">الرئيسية</NavLink>
              </li>
              <li>
                <NavLink to="/chapters">الدورات</NavLink>
              </li>
              <li>
                <NavLink to="/faq">اسئلة شائعة</NavLink>
              </li>
              <li>
                <NavLink to="/contact-us">اتصل بنا</NavLink>
              </li>
            </ul>
          </div>
          <div className="courses">
            <h4>الدورات</h4>
            <ul>
              {chapters.length !== 0 &&
                chapters.map((chap, idx) => (
                  <li>
                    <Link to={"/chapters/" + chap.id} key={idx}>
                      {chap.name}
                    </Link>
                  </li>
                ))}
              <li>
                <NavLink to="/chapters">معرفة المزيد</NavLink>
              </li>
            </ul>
          </div>
          <div className="about">
            <h4>من نحن</h4>
            <p>
              الشارح هي منصة عربية توفر كورسات مجانية عالية الجودة في مجال
              الفيزياء نسعى إلى رفع مستوى التعليم الإلكتروني في الوطن العربي كما
              توفر فرصة لاصحاب الكفاءات والخبرات والمدرسين للوصول إلى جمهور أكبر
            </p>
          </div>
        </div>
        <div className="lowerFooter">
          <div className="container">
            <p>جميع الحقوق محفوظة 2022 @ الشارح | تصميم وبرمجة ماتريكس كلاود</p>
            <div className="links">
              <a href="#">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
