import { Fragment } from "react";
import style from "./noData.module.css";
import errimg from "../../../assets/nodata/noData.svg";
import img from "../../../assets/nodata/empty.svg";
import { Link } from "react-router-dom";

const NoDataFound = ({ type }) => {
  // type 1 => no data found
  // type * => error in fetching data
  return (
    <Fragment>
      <section className={style.noData + " spliter container"}>
        {type === 1 ? (
          <Fragment>
            <div className={style.text}>
              <h4>يبدو أنه لا يوجد بيانات متعلقة بهذا القسم بعد</h4>
              <p>
                إن كنت تظن أنه هناك خطأ ما يمكنك التواصل معنا
                <Link to="/contact-us">من هنا</Link>
              </p>
            </div>
            <div className={style.img}>
              <img src={img} alt="no data found" />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className={style.text}>
              <h4>يبدو أنه قد حدث خطأ ما اثناء الحصول علي البينات</h4>
              <p>
                إن ظلت هذه المشكلة مستمرة برجاء التواصل معنا علي الرقم التالي
                <Link to="/contact-us">من هنا</Link>
              </p>
            </div>
            <div className={style.errimg}>
              <img src={errimg} alt="no data found" />
            </div>
          </Fragment>
        )}
      </section>
    </Fragment>
  );
};

export default NoDataFound;
