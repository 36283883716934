import React, { Fragment } from "react";
import "./About.css";
import VideoPreview from "../viewCourseContent/partials/video/video";
import videoTest from "../../../assets/preview/SeaV.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faYoutube,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const About = () => {
  return (
    <Fragment>
      <section className="aboutus">
        <div className="container">
          <div className="details">
            <h3>من نحن</h3>
            <p>
              الشارح هي منصة عربية توفر كورسات مجانية عالية الجودة في مجال
              الفيزياء نسعى إلى رفع مستوى التعليم الإلكتروني في الوطن العربي كما
              توفر فرصة لاصحاب الكفاءات والخبرات والمدرسين للوصول إلى جمهور أكبر
            </p>
            <div className="links">
              <a href="#">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </div>
          </div>
          <div className="img">
            <VideoPreview url={videoTest} />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
