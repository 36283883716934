import { Fragment } from "react";
import { Styles } from "./Status.css";
import { Link } from "react-router-dom";

const Status = () => {
  return (
    <Fragment>
      <section className="container statusPage">
        <p>تم تسجيل حسابك ولكن</p>
        <p className="status">
          حسابك: <span className="warning">لم يُفعل</span> بعد
        </p>
        <p>في حال تأخر الرد يرجي التواصل معنا</p>
        <Link to="/contact-us">من هنا</Link>
      </section>
    </Fragment>
  );
};

export default Status;
