import { Fragment, useEffect, useState } from "react";
import style from "./Input.module.css";

const Input = ({ type, label, validator, setSuccess, name, validate }) => {
  let [errs, setErrs] = useState([]);
  validate = !(validate === -1);
  validator = validator && validate ? validator : () => {};
  setSuccess = setSuccess ? setSuccess : () => {};
  useEffect(() => {
    setSuccess(errs.length === 0);
  });

  return (
    <Fragment>
      <div>
        <label htmlFor={name} className={style.labelModule}>
          {label}
        </label>
        <input
          className={style.inpModule}
          type={type ? type : "text"}
          id={name}
          name={name}
          onChange={(e) => {
            validator(e.target.value, setErrs);
          }}
          required
          placeholder={label}
        />
        {errs.length > 0 && validate && (
          <div className="inpErr">
            <ul>
              {errs.map((err, idx) => (
                <li key={idx}>
                  <span>{err}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Input;
