import axios from "axios";
import { useState } from "react";
import { redirect } from "react-router-dom";

const loginUser = async ({ request }) => {
  let data = await request.formData();

  let body = {
    phone: data.get("phone"),
    password: data.get("password"),
  };

  let retValues = {
    response: null,
    err: [],
  };

  await axios
    .post(`${process.env.REACT_APP_BASE_URL}login`, body)
    .then((res) => {
      retValues.response = { ...res.data.data };
    })
    .catch((err) => {
      retValues.err.push(err.response.data.message);
    });

  if (retValues.err[0] == "validation.data_error") {
    return redirect("/status");
  }

  return retValues;
};

export default loginUser;
