import axios from "axios";
import { createContext, useEffect, useState } from "react";

export let AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  let [user, setUser] = useState(null);

  const logout = () => {
    localStorage.clear("user");
    setUser(null);
  };

  const login = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) setUser(user);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
