import React, { Fragment } from "react";
import "./Home.css";
// Partials
import Services from "../serviceSection/Service";
import About from "../aboutUs/About";
import bg from "../../../assets/HomeContent/bg2.jpg";
import main from "../../../assets/HomeContent/main.svg";

const Home = () => {
  return (
    <Fragment>
      <section>
        <header>
          <div className="spliter container">
            <div>
              <h4>ابدأ التعلم يومياً</h4>
              <p>
                هي منصة تعليمية إلكترونية تهتم بشرح مادة الفيزياء باللغة العربية
                لطلاب الصف الثالث الثانوي في جمهورية مصر العربية
              </p>
            </div>
            <div className="mainIMG">
              <img src={main} alt="main image" />
            </div>
          </div>
        </header>
      </section>
      <Services />
      <About />
      <section className="howToUse spliter container">
        <div>
          <h4>التسجيل في الموقع</h4>
          <div className="steps">
            <div className="step">
              <h5>يقوم الطالب بتسجيل الدخول او تسجيل حساب جديد</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
            <div className="step">
              <h5>يقوم الطالب بتسجيل الدخول او تسجيل حساب جديد</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
            <div className="step">
              <h5>يقوم الطالب بتسجيل الدخول او تسجيل حساب جديد</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
          </div>
        </div>
        <div>
          <h4>عمليات الشراء في الموقع</h4>
          <div className="steps">
            <div className="step">
              <h5>يقوم الطالب بتسجيل الدخول او تسجيل حساب جديد</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
            <div className="step">
              <h5>يقوم الطالب بتسجيل الدخول او تسجيل حساب جديد</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
            <div className="step">
              <h5>يقوم الطالب بتسجيل الدخول او تسجيل حساب جديد</h5>
              <p>عن طريق الضغط علي الأزرار أعلي يسار الصفحة</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Home;
