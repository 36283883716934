import "./App.css";
import {
  RouterProvider,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  HashRouter,
} from "react-router-dom";
import React from "react";

// Pages
import MainLayout from "./views/Layouts/mainLayout/MainMLayout";
import Home from "./views/Pages/home/Home";
import PageNotFound from "./views/Tools/404Page/404";
import Login from "./views/Pages/login/Login";
import Chapters from "./views/Pages/chapters/Chapters";
import Register from "./views/Pages/register/Register";
import ChapterLectures from "./views/Pages/ChapterLectures/ChapterLectures";
import ShowCourseComponant from "./views/Pages/showCourseComponant/showCourseComponant";
import NoDataFound from "./views/Tools/noDataFound/noData";
import ContactUs from "./views/Pages/contactUs/ContactUs";
import FAQ from "./views/Pages/faq/FAQ";
import ViewCourse from "./views/Pages/viewCourseContent/ViewCourse";
import Pending from "./views/Pages/pendingRequest/pending";
import Status from "./views/Pages/statuspage/Status";

// Services
import loginUser from "./services/Authentication/login";
import RegisterUser from "./services/Authentication/register";
import ContactUsService from "./services/contactUs";
import AuthContextProvider from "./services/Authentication/AuthContext";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />} caseSensitive={false}>
      <Route index element={<Home />} />
      <Route
        path="chapters/"
        element={<Outlet />}
        errorElement={<NoDataFound />}>
        <Route index element={<Chapters />} />
        <Route path=":id" element={<Outlet />}>
          <Route index element={<ChapterLectures />} />
          <Route
            path="lecture/view-content/:id"
            element={<ShowCourseComponant />}
          />
          <Route path="lecture/status/:id" element={<Pending />} />
          <Route path="lecture/start-course/:id" element={<ViewCourse />} />
        </Route>
      </Route>
      <Route
        path="contact-us"
        element={<ContactUs />}
        action={ContactUsService}
      />
      <Route path="faq" element={<FAQ />} />
      <Route path="login" element={<Login />} action={loginUser} />
      <Route path="register" element={<Register />} action={RegisterUser} />
      <Route path="status" element={<Status />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

function App() {
  return (
    <AuthContextProvider>
      <RouterProvider router={router} fallbackElement={App} />
    </AuthContextProvider>
  );
}

export default App;
