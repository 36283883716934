import { Fragment } from "react";
import style from "./ViewCourse.module.css";
import { useLoaderData } from "react-router-dom";
import Tutorial from "./partials/tutorial/Tutorial";

const ViewCourse = () => {
  return (
    <Fragment>
      <section className={`container ${style.courseContainer}`}>
        <Tutorial />
      </section>
    </Fragment>
  );
};

export default ViewCourse;
