import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faMedal,
  faInfinity,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./Service.css";

const Services = () => {
  return (
    <Fragment>
      <section className="services">
        <div className="container">
          <div>
            <div className="icon">
              <FontAwesomeIcon icon={faVideo} />
            </div>
            <div className="details">
              <p className="head">100،000 دورة عبر الإنترنت</p>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
                هذا النص من مولد النص العربى
              </p>
            </div>
          </div>
          <div>
            <div className="icon">
              <FontAwesomeIcon icon={faMedal} />
            </div>
            <div className="details">
              <p className="head">تعليمات الخبراء</p>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
                هذا النص من مولد النص العربى
              </p>
            </div>
          </div>
          <div>
            <div className="icon">
              <FontAwesomeIcon icon={faInfinity} />
            </div>
            <div className="details">
              <p className="head">وصول مدى الحياة</p>
              <p>
                هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
                هذا النص من مولد النص العربى
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Services;
