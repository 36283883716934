import { Fragment, useEffect, useState } from "react";
import "./Chapters.css";
import { Link } from "react-router-dom";
import Loading from "../../Tools/loading/Loading";
import getAllChapters from "../../../services/chapters/chapter";
import NoDataFound from "../../Tools/noDataFound/noData";

const Chapters = () => {
  let [response, setResponse] = useState({
    data: [],
    err: null,
  });
  let [pending, setPending] = useState(true);

  const getData = async () => {
    let values = await getAllChapters();
    setResponse(values);
    setPending(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const viewCondition = () => {
    if (response.err === null) {
      if (response.data.length !== 0)
        return (
          <section className="container ChapterContainer LevelContainer">
            {response.data.map((chapter) => (
              <Link to={"./" + chapter.id} className="chapter" key={chapter.id}>
                <p>{chapter.name}</p>
              </Link>
            ))}
          </section>
        );
      else return <NoDataFound type={1} />;
    } else return <NoDataFound />;
  };

  return (
    <Fragment>
      {pending && <Loading />}
      {viewCondition()}
    </Fragment>
  );
};

export default Chapters;
