import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./showCourseComponant.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faFilePdf,
  faPaperPlane,
  faPenClip,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {
  getLectureContent,
  requestCourse,
} from "../../../services/Lectures/lecture";

import Loading from "../../Tools/loading/Loading";
import NoDataFound from "../../Tools/noDataFound/noData";
import { AuthContext } from "../../../services/Authentication/AuthContext";

const ShowCourseComponant = () => {
  const { user } = useContext(AuthContext);
  let { id } = useParams();
  let [lecture, setLecture] = useState(null);
  let [requested, setRequested] = useState(false);
  let [pending, setPending] = useState(true);
  let [errors, setErrors] = useState([]);

  const getLectureData = async () => {
    const { response, err } = await getLectureContent(id);
    console.log(err);
    setErrors(err);

    if (response) {
      setLecture(response);
      setRequested(response.status === "pending");
    }
    setPending(false);
  };

  useEffect(() => {
    getLectureData();
  }, []);

  const requestToOpenCourse = async () => {
    setPending(true);
    const { response, err } = await requestCourse(id);
    if (response) {
      setRequested(true);
    }

    setErrors(err);

    setPending(false);
  };

  const contentType = (type) => {
    if (type === "tutorial") {
      return "الشــرح";
    } else if (type === "assignments") {
      return "واجــب منزلي";
    } else {
      return "امتحان الحصة";
    }
  };

  const contentPartialType = (type) => {
    if (type === "video") return faVideo;
    else if (type === "quiz") return faPenClip;
    else if (type === "pdf") return faFilePdf;
  };

  const toggleView = () => {
    if (errors.length === 0) {
      return (
        <section className="container closedCourse">
          {lecture && (
            <Fragment>
              <div className="content">
                <div className="img">
                  <img src={lecture.image} />
                  <div className={"status closed"}>
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                </div>
                <div className="details">
                  <h3>{lecture.title}</h3>
                  <p>{lecture.description}</p>
                  <p className="price">بسعر {lecture.price} ج.م</p>
                </div>
              </div>

              <aside>
                {user ? (
                  <div className="paymentStatus">
                    <h5>يبدو أنك لم تحصل علي المحاضرة بعد</h5>
                    <p>
                      يمكنك الحصول علي محتويات المحاضرة عن طريق تقديم طلب
                      للمشرفين
                    </p>
                    {requested ? (
                      <button disabled>تم الطلب</button>
                    ) : (
                      <button onClick={requestToOpenCourse}>من هنا</button>
                    )}
                  </div>
                ) : (
                  <div className="paymentStatus">
                    <h5>تحتاج إي تسجيل الدخول</h5>
                    <p>حتي تتمكن من فتح هذه المحاضرة</p>
                    <Link to="/login">تسجيل الدخول</Link>
                  </div>
                )}

                {lecture.data.map((content) => (
                  <div className="lectContent">
                    <p className="headline">{contentType(content.type)}</p>
                    <ul>
                      {content.content &&
                        content.content.map((c) => (
                          <li>
                            <FontAwesomeIcon
                              icon={contentPartialType(c.type)}
                            />
                            <span className="name">{c.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
              </aside>
            </Fragment>
          )}
        </section>
      );
    } else {
      return <NoDataFound type={2} />;
    }
  };

  return (
    <Fragment>
      {pending && <Loading />}
      {toggleView()}
    </Fragment>
  );
};

export default ShowCourseComponant;
